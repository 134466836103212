import { Heading } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { useId, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Card } from 'modules/shared';
import { MainActivity } from '../types';
import { ContactForm } from './ContactSection/ContactForm';
import { SubmitConfirmation } from './ContactSection/SubmitConfirmation';
import { ANIMATION_PROPS } from './ContactSection';

const ApiSection = () => {
  const { t } = useTranslation('business');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const formWrapperId = useId();

  return (
    <Card>
      <AnimatePresence initial={false} mode="wait">
        {formSubmitted ? (
          <SubmitConfirmation size="s" onBack={() => setFormSubmitted(false)} />
        ) : (
          <motion.div {...ANIMATION_PROPS} key={formWrapperId}>
            <Heading as="h3" variant="xs" className="mb-3">
              {t('contactSection.title')}
            </Heading>

            <ContactForm
              variant="vertical"
              mainActivity={MainActivity.Classified}
              onSubmit={() => setFormSubmitted(true)}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Card>
  );
};

export { ApiSection };
