import { TextField, Checkbox, LinkButton, Button, StatusMessage } from '@carvertical/ui';
import { Trans, useTranslation } from 'next-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Link } from 'components/common/Link';
import { FormField } from 'components/common/ui/forms';
import { useRouteData } from 'context/RouteDataProvider';
import { useRecaptcha } from 'modules/auth';
import * as dataLayer from 'services/dataLayer';
import { basicFormSchema, type BasicFormFields } from '../../schemas';
import { useB2bContactMutation } from '../../hooks';
import type { BasicFormValues } from '../../types';
import { WebinarSubmitConfirmation } from './WebinarSubmitConfirmation';

const DEAL_SOURCE = 'Inbound [marketing lead]';
const FIELD_PROPS = {
  color: 'outlined',
  fullWidth: true,
  filled: true,
  requiredIndicatorShown: false,
};

const WebinarForm = () => {
  const { t } = useTranslation(['business', 'common']);
  const { getCaptchaResponse } = useRecaptcha();
  const { market } = useRouteData();

  const { contactB2b, contactingB2b, b2bContactError, b2bContacted, reset } =
    useB2bContactMutation();

  const form = useForm<BasicFormFields>({
    resolver: zodResolver(basicFormSchema),
  });
  const { formState, handleSubmit } = form;
  const submitting = formState.isSubmitting || contactingB2b;
  const success = formState.isSubmitSuccessful && b2bContacted;
  const error = b2bContactError;

  const submit = async (values: BasicFormValues) => {
    contactB2b({
      ...values,
      dealSource: DEAL_SOURCE,
      dealSourceUrl: window.location.href,
      countryCode: market.countryCode,
      captchaResponse: await getCaptchaResponse(),
    });

    dataLayer.push('b2bFormSubmitted');
  };

  const Field = FormField<BasicFormFields>;

  return success ? (
    <WebinarSubmitConfirmation />
  ) : (
    <FormProvider {...form}>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <h1 className="text-h-xl">{t('webinar.title')}</h1>

          <p className="text-l">
            <Trans t={t} i18nKey="webinar.description" components={{ 1: <strong /> }} />
          </p>
        </div>

        {error && (
          <StatusMessage
            closeButtonShown
            onCloseButtonClick={() => {
              reset();
            }}
            title={t('common:general.errorTitle')}
            variant="error"
          >
            {t('common:general.errorText')}
          </StatusMessage>
        )}

        <form className="flex w-full flex-col items-stretch gap-2" onSubmit={handleSubmit(submit)}>
          <Field name="name">
            <TextField label={t('contactSection.labels.name')} {...FIELD_PROPS} />
          </Field>

          <Field name="email">
            <TextField label={t('contactSection.labels.email')} {...FIELD_PROPS} />
          </Field>

          <Field name="companyName">
            <TextField label={t('contactSection.labels.companyName')} {...FIELD_PROPS} />
          </Field>

          <Field name="termsAccepted">
            <Checkbox
              label={
                <Trans
                  i18nKey="webinar.termsAccepted"
                  t={t}
                  as="span"
                  components={{
                    1: <LinkButton as={Link} href="/policies" target="_blank" size="m" />,
                  }}
                />
              }
            />
          </Field>

          <Button variant="yellow" type="submit" size="l" disabled={submitting}>
            {submitting ? `${t('common:general.pleaseWaitLabel')}…` : t('webinar.ctaLabel')}
          </Button>
        </form>
      </div>
    </FormProvider>
  );
};

export { WebinarForm };
