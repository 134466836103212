import { useTranslation } from 'next-i18next';
import ArtDirectedImage from 'components/common/ArtDirectedImage';
import type { WebinarPresentation } from '../../types';

type WebinarCarouselItemProps = WebinarPresentation;

const WebinarCarouselItem = ({
  context,
  illustration,
  name,
  title,
  theme,
}: WebinarCarouselItemProps) => {
  const { t } = useTranslation('business');

  return (
    <div className="flex flex-col items-center gap-3 sm:h-[700px] md:h-[860px]">
      <p className="text-h-xl text-center text-white">{t('webinar.presenter', { context })}</p>

      <ArtDirectedImage
        className="pointer-events-none"
        // @ts-expect-error TS(2739)
        srcSet={{
          forTabletPortraitUp: {
            src: illustration,
            width: 400,
            height: 393,
          },
          forDesktopUp: {
            src: illustration,
            width: 462,
            height: 454,
          },
        }}
        src={illustration}
        quality={100}
        width={277}
        height={276}
      />

      <div className="flex flex-col items-center gap-1 md:gap-2">
        <h3 className="text-h-l text-center text-white md:text-h-m">{theme}</h3>

        <div className="flex flex-col">
          <p className="text-h-xs text-center text-white">{name}</p>

          <p className="text-center text-s text-white">{title}</p>
        </div>
      </div>
    </div>
  );
};

export { WebinarCarouselItem };
