import { Grid, Stack, Text } from '@carvertical/ui';
import { motion } from 'framer-motion';
import cx from 'classnames';
import { mapValues, values } from 'lodash';
import { useState } from 'react';
import { useMedia, useMount } from 'react-use';
import Flag from 'components/common/Flag';
import { getCountryName } from 'services/countries';
import { createMediaQuery } from 'utils/styles';
import { getAvailableMarkets } from 'utils/market';
import breakpoint from 'styles/variables.module.scss';
import { EXCLUDED_MARKETS } from 'constants/markets';
import styles from './MarketList.module.scss';

type MarketListProps = {
  align?: 'left' | 'center';
};

const MARKETS: string[] = values(mapValues(getAvailableMarkets(EXCLUDED_MARKETS), 'countryCode'));

const FLAGS_TO_SHOW = {
  mobile: 11,
  desktop: 24,
};

const COUNTRIES_WRAPPER_VARIANTS = {
  visible: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const COUNTRIES_VARIANTS = {
  hidden: { opacity: 0, scale: 0 },
  visible: { opacity: 1, scale: 1 },
};

const CountryTag = ({ countryCode }: { countryCode: string }) => (
  <Stack crossAxisAlign="center" type="horizontal" className={styles.flagWrapper}>
    {/* @ts-expect-error TS(TS2741) */}
    <Flag size={16} countryCode={countryCode} rounded />
    <Text variant="s">{getCountryName(countryCode)}</Text>
  </Stack>
);

const MarketList = ({ align = 'center' }: MarketListProps) => {
  const [allCountriesShown, setAllCountriesShown] = useState(false);
  const [isClient, setIsClient] = useState(false);

  const isTabletPortrait = useMedia(createMediaQuery(breakpoint.tabletPortraitBreakpoint), false);

  useMount(() => setIsClient(true));

  const index = isTabletPortrait ? FLAGS_TO_SHOW.desktop : FLAGS_TO_SHOW.mobile;
  const mainCountries = MARKETS.slice(0, index);
  const otherCountries = MARKETS.slice(index, MARKETS.length);

  if (!isClient) {
    return null;
  }

  return (
    <Grid columnCount={{ mobileUp: 1, desktopUp: 12 }}>
      <motion.div
        key={allCountriesShown ? 'all' : 'main'} // https://github.com/framer/motion/issues/776
        className={cx(styles.markets, styles[align])}
        initial="hidden"
        animate="visible"
        variants={COUNTRIES_WRAPPER_VARIANTS}
      >
        {mainCountries.map((countryCode) => (
          <CountryTag key={countryCode} countryCode={countryCode} />
        ))}

        {allCountriesShown &&
          otherCountries.map((countryCode) => (
            <motion.div variants={COUNTRIES_VARIANTS} key={countryCode}>
              <CountryTag countryCode={countryCode} />
            </motion.div>
          ))}

        {!allCountriesShown && (
          <Stack
            as="button"
            crossAxisAlign="center"
            type="horizontal"
            aria-label="Show all countries"
            onClick={() => setAllCountriesShown(true)}
            className={cx(styles.expandButton, styles.flagWrapper)}
          >
            <Text variant="s" textColor="inherited">
              + {otherCountries.length}
            </Text>
          </Stack>
        )}
      </motion.div>
    </Grid>
  );
};

export { MarketList };
