import Page from 'components/common/Page';
import { Header } from 'modules/shared';
import { TrustBar } from '../TrustBar';
import { CampaignHero } from './CampaignHero';

const CampaignPage = () => (
  <Page breadcrumbs={false} header={<Header logoOnly logoInteractive={false} />}>
    <CampaignHero />

    <TrustBar type="custom-partner" />
  </Page>
);

export { CampaignPage };
