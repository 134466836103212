import { Container } from '@carvertical/ui';
import { useCampaign } from '../../hooks';
import { CampaignForm } from './CampaignForm';
import { CampaignHeroIllustration } from './CampaignHeroIllustration';

const CampaignHero = () => {
  const {
    campaignContent: { text },
  } = useCampaign();

  return (
    <div className="relative flex items-center bg-blue-300 md:min-h-[900px]">
      <Container className="flex-grow">
        <div className="grid grid-cols-1 items-center md:grid-cols-2 md:gap-4">
          <div className="flex py-6 md:py-15">
            <CampaignForm
              ctaLabel={text.cta}
              successDescription={text.successDescription}
              successTitle={text.successTitle}
              title={text.title}
              description={text.description}
              variant="light"
            />
          </div>

          <CampaignHeroIllustration />
        </div>
      </Container>
    </div>
  );
};

export { CampaignHero };
