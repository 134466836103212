import { useTranslation } from 'next-i18next';
import Page from 'components/common/Page';
import { TestimonialsSection } from 'components/landings/common/TestimonialsSection';
import { BlogSection } from 'components/landings/common/BlogSection';
import { MainActivity, type LandingType } from '../types';
import { BusinessPageHero } from './BusinessPageHero';
import { ContactSection } from './ContactSection';
import { BenefitsSection } from './BenefitsSection';
import { TeamSection } from './TeamSection';
import { TrustSection } from './TrustSection';

const TYPE: LandingType = 'api';

const ApiPage = () => {
  const { t } = useTranslation('business');

  return (
    <Page breadcrumbs={false}>
      <BusinessPageHero type={TYPE} />

      <TestimonialsSection type={TYPE} />

      <BenefitsSection type={TYPE} />

      <TeamSection />

      <TrustSection />

      <ContactSection
        mainActivity={MainActivity.Api}
        title={t('contactSection.apiIntegrationTitle')}
        ctaLabel={t('contactSection.requestLabel')}
      />

      <BlogSection />
    </Page>
  );
};

export { ApiPage };
