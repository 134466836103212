import { supportApi } from 'services/support-api';
import type { B2bContactResponse, B2bContactParams } from './types';

const postB2bContact = async (params: B2bContactParams) => {
  const { data } = await supportApi.post<B2bContactResponse>('/hubspot/contact-b2b', {
    ...params,
  });

  return data;
};

export { postB2bContact };
