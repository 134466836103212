import cx from 'classnames';
import { Stack } from '@carvertical/ui';
import { range } from 'lodash';
import StarIcon from 'assets/icons/star.svg';
import styles from './StarRating.module.scss';

type StarRatingProps = {
  rating: 0 | 1 | 2 | 3 | 4 | 5;
  color?: 'blue' | 'yellow' | 'white';
  size?: 'm' | 's';
  className?: string;
};

const StarRating = ({ className, color = 'blue', rating, size = 'm' }: StarRatingProps) => (
  <Stack gap={0.5} type="horizontal" className={className}>
    {range(5).map((number) => (
      <StarIcon
        name="star"
        key={number}
        className={cx(styles.star, styles[color], styles[size], number >= rating && styles.light)}
      />
    ))}
  </Stack>
);

export { StarRating };
export type { StarRatingProps };
