import PropTypes from 'prop-types';
import { range } from 'lodash';
import classnames from 'classnames';
import styles from './Loading.module.scss';

const Loading = ({ className, type, block }) => (
  <div className={classnames(styles.root, styles[type], block && styles.block, className)}>
    {type === 'regular' && range(5).map((index) => <div key={index} className={styles.line} />)}
    {type === 'circle' && range(12).map((index) => <div key={index} className={styles.dot} />)}
  </div>
);

Loading.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['regular', 'circle']),
  block: PropTypes.bool,
};
Loading.defaultProps = {
  className: undefined,
  type: 'regular',
  block: false,
};

export default Loading;
