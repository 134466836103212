import PropTypes from 'prop-types';
import { onlyText } from 'react-children-utilities';
import withRouteData from '../../hoc/withRouteData';
import { stripHtml } from '../../utils/text';
import { format as formatMoney } from '../../utils/money';
import { Tooltip, TooltipContent, TooltipTrigger } from './Tooltip';
import styles from './Price.module.scss';

const OFFSET = {
  mainAxis: 1,
  crossAxis: 0,
};
const AMOUNT_PATTERN = /\d+[.,]?\d*/;

const Price = ({
  as: RenderAs,
  placement,
  appendTo,
  innerHtml,
  children,
  routeData: { market },
  ...props
}) => {
  const newProps = {
    children,
    ...(innerHtml && { dangerouslySetInnerHTML: { __html: innerHtml } }),
    ...props,
  };

  const content = <RenderAs {...newProps} />;

  if (!market.secondaryCurrency) {
    return content;
  }

  const {
    currency,
    secondaryCurrency: { currency: secondaryCurrency, conversionRate },
  } = market;

  const text = onlyText(children) || stripHtml(innerHtml);
  const amount = Number(text.match(AMOUNT_PATTERN)?.[0]);

  if (!amount || text !== formatMoney(amount, currency)) {
    return content;
  }

  return (
    <Tooltip
      open
      arrowShown={false}
      offsetValue={OFFSET}
      plain
      placement={placement}
      {...(appendTo && { appendTo })}
    >
      <TooltipTrigger plain>{content}</TooltipTrigger>

      <TooltipContent style={{ zIndex: 1 }} className={styles.tooltip}>
        {`= ${formatMoney(amount / conversionRate, secondaryCurrency)}`}
      </TooltipContent>
    </Tooltip>
  );
};

Price.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  placement: PropTypes.string,
  appendTo: PropTypes.func,
  innerHtml: PropTypes.string,
  children: PropTypes.node,
  routeData: PropTypes.shape().isRequired,
};
Price.defaultProps = {
  as: 'span',
  placement: 'top',
  appendTo: undefined,
  innerHtml: '',
  children: undefined,
};

export default withRouteData(Price);
