import { useTranslation } from 'next-i18next';
import { Text, Grid, GridItem, Heading, Stack, Button } from '@carvertical/ui';
import { type AnimationProps, motion } from 'framer-motion';
import { useRef } from 'react';
import CircleCheckIcon from 'assets/illustrations/circle-check.svg';

type SubmitConfirmationProps = {
  size?: 's' | 'm';
  onBack: () => void;
};

const ANIMATION_PROPS: AnimationProps = {
  variants: {
    hidden: { opacity: 0, height: 0 },
    visible: { opacity: 1, height: 'auto' },
  },
  transition: {
    duration: 0.5,
    ease: 'easeInOut',
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
};

const SubmitConfirmation = ({ size = 'm', onBack }: SubmitConfirmationProps) => {
  const { t } = useTranslation(['business', 'common']);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const rootRef = useRef<HTMLDivElement>(null);

  const small = size === 's';

  const centerElementInView = () => {
    buttonRef.current?.focus();
    rootRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <motion.div
      {...ANIMATION_PROPS}
      key="success"
      onAnimationComplete={centerElementInView}
      ref={rootRef}
    >
      <Grid
        columnCount={{
          mobileUp: 1,
          tabletLandscapeUp: 12,
        }}
      >
        <GridItem
          start={{
            mobileUp: 1,
            tabletLandscapeUp: 3,
          }}
          span={{ mobileUp: 1, tabletLandscapeUp: 8 }}
        >
          <Stack gap={3} crossAxisAlign="center">
            <Stack crossAxisAlign="center" gap={2}>
              <CircleCheckIcon original className={small ? 'h-8 w-8' : 'h-15 w-15'} />

              <Heading as="h3" variant={small ? 's' : 'l'} align="center">
                {t('contactSection.success.title')}
              </Heading>
            </Stack>

            <Text variant={small ? 'm' : 'l'} align="center">
              {t('contactSection.success.text')}
            </Text>

            <Button variant="blue" size={small ? 'm' : 'xxl'} onClick={onBack} ref={buttonRef}>
              {t('common:general.backAction')}
            </Button>
          </Stack>
        </GridItem>
      </Grid>
    </motion.div>
  );
};

export { SubmitConfirmation };
