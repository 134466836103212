import { Container, Heading, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { NS_BUSINESS } from 'constants/i18n';
import { Section } from 'components/landings/common/Section';
import { MarketList } from 'components/landings/common/MarketList';
import EuropeMapIllustration from '../assets/images/europe-map.svg';

const TrustSection = () => {
  const { t } = useTranslation(NS_BUSINESS);

  return (
    <Section color="colorBlue" className="relative isolate z-10">
      <Container>
        <div className="grid grid-cols-1 gap-x-4 gap-y-3 lg:grid-cols-12 lg:py-10">
          <div className="col-span-1 flex flex-col gap-3 lg:col-span-7 lg:gap-5">
            <div className="flex flex-col items-center gap-2 text-center lg:items-start lg:text-left">
              <Heading as="h2" variant="l" textColor="light">
                {t('trustSection.title')}
              </Heading>

              <Text variant="l" textColor="light">
                {t('trustSection.description')}
              </Text>
            </div>

            <MarketList align="left" />
          </div>

          <div className="relative col-span-1 flex min-h-56 justify-end lg:static lg:col-span-5">
            <EuropeMapIllustration className="absolute bottom-0 left-auto right-0 top-0 -z-10 w-full translate-x-12 scale-125 transform lg:w-96 lg:translate-x-16" />
          </div>
        </div>
      </Container>
    </Section>
  );
};

export { TrustSection };
