import { create } from 'zustand';

type CampaignStore = {
  campaignSuccessful?: boolean;
  actions: {
    setCampaignSuccessful: (successful?: boolean) => void;
  };
};

const useCampaignStore = create<CampaignStore>((set) => ({
  campaignSuccessful: false,
  actions: {
    setCampaignSuccessful: (succesful) => set({ campaignSuccessful: succesful ?? false }),
  },
}));

const useCampaignSuccessful = () => useCampaignStore((store) => store.campaignSuccessful);
const useCampaignActions = () => useCampaignStore((store) => store.actions);

export { useCampaignStore, useCampaignSuccessful, useCampaignActions };
