import Page from 'components/common/Page';
import { Header } from 'modules/shared';
import { WebinarHero } from './WebinarHero';

const WebinarPage = () => {
  return (
    <Page breadcrumbs={false} header={<Header logoOnly logoInteractive={false} />}>
      <WebinarHero />
    </Page>
  );
};

export { WebinarPage };
