import { useRouteData } from 'context/RouteDataProvider';
import { FaqSection } from 'components/common/sections/FaqSection';
import styles from './BusinessFaqSection.module.scss';

type BusinessFaqSectionProps = {
  className?: string;
};

const BusinessFaqSection = ({ className }: BusinessFaqSectionProps) => {
  const { faq } = useRouteData();

  if (!faq) {
    return null;
  }

  const faqItems = faq[0]?.questions.map((question) => ({
    id: question.slug,
    headingLevel: 'h3' as const,
    buttonNode: question.question,
    contentNode: (
      <span
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: question.answer.html,
        }}
        className={styles.answer}
      />
    ),
  }));

  return <FaqSection sectionVariant="l" className={className} items={faqItems} />;
};

export { BusinessFaqSection };
