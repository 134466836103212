import { useTranslation } from 'next-i18next';
import {
  Heading,
  Container,
  Text,
  Stack,
  SegmentedControl,
  SegmentedControlOption,
} from '@carvertical/ui';
import { AnimatePresence, type AnimationProps, motion } from 'framer-motion';
import cx from 'classnames';
import { useId, useState } from 'react';
import { sortBy } from 'lodash';
import { useRouteData } from 'context/RouteDataProvider';
import { NS_BUSINESS } from 'constants/i18n';
import type { ContentType } from 'modules/business/types';
import { Section } from '../Section';
import { TestimonialCard } from './TestimonialCard';
import styles from './TestimonialsSection.module.scss';

type TestimonialsSectionProps = {
  className?: string;
} & ContentType;

enum TestimonialType {
  Business = 0,
  Influencer = 1,
}

const TESTIMONIALS_LIMIT = 3;

const CARD_ANIMATION_PROPS: AnimationProps = {
  variants: {
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        ease: 'easeOut',
        duration: 0.2,
      },
    },
    hidden: (reverse: boolean) => ({
      opacity: 0,
      x: reverse ? 96 : -96,
      transition: {
        ease: 'easeIn',
        duration: 0.25,
      },
    }),
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
};

const TestimonialsSection = ({ className, type }: TestimonialsSectionProps) => {
  const { businessTestimonials, reviews: influencerReviews } = useRouteData();
  const { t } = useTranslation(NS_BUSINESS);
  const id = useId();

  const businessTestimonialsEmpty = !businessTestimonials?.length;
  const influencerReviewsEmpty = !influencerReviews?.length;

  const [testimonialsType, setTestimonialsType] = useState<TestimonialType>(
    businessTestimonialsEmpty ? TestimonialType.Influencer : TestimonialType.Business,
  );
  const businessTestimonialsActive = testimonialsType === TestimonialType.Business;

  const tabId = {
    business: `business-tab-${id}`,
    influencer: `influencer-tab-${id}`,
  };

  const tabPanelId = {
    business: `business-panel-${id}`,
    influencer: `influencer-panel-${id}`,
  };

  const switchShown = !businessTestimonialsEmpty && !influencerReviewsEmpty && type === 'business';

  if (businessTestimonialsEmpty && influencerReviewsEmpty) {
    return null;
  }

  return (
    <Section className={cx(styles.root, className)}>
      <Container>
        <Stack gap={{ mobileUp: 5, tabletLandscapeUp: 8 }} crossAxisAlign="center">
          <Stack
            crossAxisAlign="center"
            gap={{
              mobileUp: 2,
              tabletLandscapeUp: 3,
            }}
          >
            <Stack gap={2} crossAxisAlign="center">
              <Heading as="h2" variant="l" align="center">
                {t('reviewsSection.title')}
              </Heading>

              <Text variant="l" align="center">
                {t('reviewsSection.description')}
              </Text>
            </Stack>

            {switchShown && (
              <SegmentedControl
                variant="grey"
                onChange={setTestimonialsType}
                selectedIndex={testimonialsType}
              >
                <SegmentedControlOption aria-controls={tabPanelId.business} id={tabId.business}>
                  {t('reviewsSection.dealerReviewsLabel')}
                </SegmentedControlOption>

                <SegmentedControlOption aria-controls={tabPanelId.influencer} id={tabId.influencer}>
                  {t('reviewsSection.influencersLabel')}
                </SegmentedControlOption>
              </SegmentedControl>
            )}
          </Stack>

          <AnimatePresence mode="wait" initial={false}>
            {businessTestimonialsActive ? (
              <motion.div
                className={styles.testimonials}
                {...CARD_ANIMATION_PROPS}
                key={tabPanelId.business}
                aria-labelledby={tabId.business}
                role="tabpanel"
                custom
              >
                {sortBy(businessTestimonials, ['order'])
                  .slice(0, TESTIMONIALS_LIMIT)
                  .map((testimonial) => (
                    <TestimonialCard
                      key={testimonial.name}
                      testimonial={testimonial}
                      className={styles.card}
                    />
                  ))}
              </motion.div>
            ) : (
              <motion.div
                className={styles.testimonials}
                {...CARD_ANIMATION_PROPS}
                key={tabPanelId.influencer}
                aria-labelledby={tabId.influencer}
                role="tabpanel"
              >
                {influencerReviews
                  ?.slice(0, TESTIMONIALS_LIMIT)
                  ?.map((testimonial) => (
                    <TestimonialCard
                      key={testimonial.youtubeId}
                      testimonial={testimonial}
                      className={styles.card}
                    />
                  ))}
              </motion.div>
            )}
          </AnimatePresence>
        </Stack>
      </Container>
    </Section>
  );
};

export { TestimonialsSection };
