import { useTranslation } from 'next-i18next';
import { toLower } from 'lodash';
import type { ParseKeys } from 'i18next';
import { useRouteData } from 'context/RouteDataProvider';
import { isExistingMarket } from 'utils/market';
import { getLanguage } from 'utils/locale';
import { Section } from 'components/landings/common/Section';
import { MILES_MARKETS } from 'constants/markets';
import { BENEFITS_SECTION_ID, CONTACT_SECTION_ID, PRICING_SECTION_ID } from '../constants';
import imageService from '../assets/images/benefits-service.png';
import type { ContentType } from '../types';
import { BenefitBlock } from './BenefitBlock';

type SectionItem = {
  titleKey: string;
  descriptionKey: string;
  imageSrc: string;
  fallbackImageSrc?: string;
  ctaKey: `common:${ParseKeys}` | `business:${ParseKeys<'business'>}`;
  ctaAnchorId?: string;
};

type SectionProps = {
  milesUnit: boolean;
  language?: string;
  locale?: string;
};

type BenefitsSectionProps = ContentType;

const BUSINESS_SECTIONS: ({ language, locale, milesUnit }: SectionProps) => SectionItem[] = ({
  language,
  locale,
  milesUnit,
}) => [
  {
    titleKey: 'benefitsSection.benefit.0.title',
    descriptionKey: 'benefitsSection.benefit.0.description',
    imageSrc: `/images/business-page/benefit-mileage-${milesUnit ? 'mi' : 'km'}.png`,
    ctaKey: 'common:general.giveTrialAction',
    ctaAnchorId: PRICING_SECTION_ID,
  },
  {
    titleKey: 'insuranceBenefitsSection.benefit.2.title',
    descriptionKey: 'insuranceBenefitsSection.benefit.2.description',
    imageSrc: '/images/business-page/custom-solution-benefit.png',
    ctaKey: 'business:general.getCustomSolutionAction',
  },
  {
    titleKey: 'benefitsSection.benefit.2.title',
    descriptionKey: 'benefitsSection.benefit.2.description',
    imageSrc: `/images/business-page/benefit-2-${toLower(locale)}.png`,
    fallbackImageSrc: '/images/business-page/benefit-2-en.png',
    ctaKey: 'common:general.contactUsActionFormal',
  },
  {
    titleKey: 'benefitsSection.benefit.3.title',
    descriptionKey: 'benefitsSection.benefit.3.description',
    imageSrc: imageService,
    ctaKey: 'business:general.becomePartnerLabel',
  },
  {
    titleKey: 'benefitsSection.benefit.1.title',
    descriptionKey: 'benefitsSection.benefit.1.description',
    imageSrc: `/images/business-page/benefit-1-${toLower(language)}.png`,
    fallbackImageSrc: '/images/business-page/benefit-1-en.png',
    ctaKey: 'common:general.getInTouchAction',
  },
];

const INSURANCE_SECTIONS: ({ milesUnit }: SectionProps) => SectionItem[] = ({ milesUnit }) => [
  {
    titleKey: 'insuranceBenefitsSection.benefit.1.title',
    descriptionKey: 'insuranceBenefitsSection.benefit.1.description',
    imageSrc: '/images/business-page/benefit-api.png',
    ctaKey: 'common:general.tryFreeActionFormal',
  },
  {
    titleKey: 'insuranceBenefitsSection.benefit.0.title',
    descriptionKey: 'insuranceBenefitsSection.benefit.0.description',
    imageSrc: `/images/business-page/benefit-mileage-${milesUnit ? 'mi' : 'km'}.png`,
    ctaKey: 'common:general.contactUsActionFormal',
  },

  {
    titleKey: 'insuranceBenefitsSection.benefit.2.title',
    descriptionKey: 'insuranceBenefitsSection.benefit.2.description',
    imageSrc: '/images/business-page/custom-solution-benefit.png',
    ctaKey: 'business:general.getCustomSolutionAction',
  },
];

const LEASING_SECTIONS: ({ milesUnit }: SectionProps) => SectionItem[] = ({ milesUnit }) => [
  {
    titleKey: 'leasingBenefitsSection.benefit.0.title',
    descriptionKey: 'leasingBenefitsSection.benefit.0.description',
    imageSrc: '/images/business-page/benefit-api.png',
    ctaKey: 'common:general.tryFreeActionFormal',
  },
  {
    titleKey: 'leasingBenefitsSection.benefit.1.title',
    descriptionKey: 'leasingBenefitsSection.benefit.1.description',
    imageSrc: `/images/business-page/benefit-mileage-${milesUnit ? 'mi' : 'km'}.png`,
    ctaKey: 'common:general.giveTrialAction',
  },
  {
    titleKey: 'leasingBenefitsSection.benefit.2.title',
    descriptionKey: 'leasingBenefitsSection.benefit.2.description',
    imageSrc: '/images/business-page/leasing-benefit-2.png',
    ctaKey: 'common:general.contactUsActionFormal',
  },
  {
    titleKey: 'leasingBenefitsSection.benefit.3.title',
    descriptionKey: 'leasingBenefitsSection.benefit.3.description',
    imageSrc: '/images/business-page/custom-solution-benefit.png',
    ctaKey: 'business:general.getCustomSolutionAction',
  },
];

const API_SECTIONS = (): SectionItem[] => [
  {
    titleKey: 'apiBenefitsSection.benefit.0.title',
    descriptionKey: 'apiBenefitsSection.benefit.0.description',
    imageSrc: '/images/business-page/benefit-integration.png',
    ctaKey: 'common:general.requestAction',
  },
  {
    titleKey: 'apiBenefitsSection.benefit.1.title',
    descriptionKey: 'apiBenefitsSection.benefit.1.description',
    imageSrc: '/images/business-page/benefit-advanced-history-api.png',
    ctaKey: 'common:general.contactUsActionFormal',
  },
  {
    titleKey: 'apiBenefitsSection.benefit.2.title',
    descriptionKey: 'apiBenefitsSection.benefit.2.description',
    imageSrc: '/images/business-page/benefit-decoder.png',
    ctaKey: 'common:general.tryItNowAction',
  },
  {
    titleKey: 'insuranceBenefitsSection.benefit.1.title',
    descriptionKey: 'insuranceBenefitsSection.benefit.1.description',
    imageSrc: '/images/business-page/benefit-api.png',
    ctaKey: 'common:general.tryFreeActionFormal',
  },
];

const SECTIONS = {
  business: BUSINESS_SECTIONS,
  insurance: INSURANCE_SECTIONS,
  leasing: LEASING_SECTIONS,
  api: API_SECTIONS,
};

const SECTION_EVEN_INDEX = {
  business: 0,
  insurance: 0,
  leasing: 1,
  api: 1,
};

const BenefitsSection = ({ type }: BenefitsSectionProps) => {
  const { t } = useTranslation(['business', 'common']);
  const { locale, market } = useRouteData();

  const sections = SECTIONS[type]({
    locale,
    language: getLanguage(locale),
    milesUnit: isExistingMarket({ markets: MILES_MARKETS, market }),
  });

  return (
    <>
      {sections.map(
        (
          {
            ctaAnchorId = CONTACT_SECTION_ID,
            ctaKey,
            descriptionKey,
            fallbackImageSrc,
            imageSrc,
            titleKey,
          },
          index,
        ) => (
          <Section
            variant="l"
            id={BENEFITS_SECTION_ID}
            key={titleKey}
            color={(index + 1) % 2 === SECTION_EVEN_INDEX[type] ? 'colorBlue' : 'colorBlue900'}
          >
            <BenefitBlock
              // @ts-expect-error TS(2322)
              title={t(titleKey)}
              // @ts-expect-error TS(2322)
              description={t(descriptionKey)}
              imageSrc={imageSrc}
              reverse={(index + 1) % 2 === SECTION_EVEN_INDEX[type]}
              fallbackImageSrc={fallbackImageSrc}
              ctaText={t(ctaKey)}
              ctaAnchorId={ctaAnchorId}
            />
          </Section>
        ),
      )}
    </>
  );
};

export { BenefitsSection };
