import { useEffect, useId, useRef, useState } from 'react';
import cx from 'classnames';
import { type AnimationPlaybackControls, useAnimate } from 'framer-motion';
import styles from './Ticker.module.scss';

enum TickerDirection {
  normal = 1,
  reverse = -1,
}

type TickerProps = {
  children: React.ReactNode[];
  direction?: TickerDirection;
  variant?: 'horizontal' | 'vertical';
  className?: string;
  wrapperClassName?: string;
  duration?: number;
};

const DEFAULT_DURATION = 45;

const Ticker = ({
  children,
  className,
  wrapperClassName,
  direction = TickerDirection.normal,
  duration = DEFAULT_DURATION,
  variant = 'horizontal',
}: TickerProps) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const id = useId();

  const [ready, setReady] = useState(false);
  const [contentWidth, setContentWidth] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);
  const [duplicates, setDuplicates] = useState(1);
  const [animationScope, animate] = useAnimate();
  const [animationControls, setAnimationControls] = useState<AnimationPlaybackControls>();

  const vertical = variant === 'vertical';
  const horizontal = variant === 'horizontal';

  useEffect(() => {
    let width = 0;
    let height = 0;

    for (let index = 0; index < children.length; index += 1) {
      const element = document.getElementById(`${id}-${index}`);

      if (element) {
        width += element.clientWidth;
        height += element.clientHeight;
      }
    }

    setContentHeight(height);
    setContentWidth(width);

    setReady(true);
  }, [animationScope, children.length, id, direction, variant]);

  useEffect(() => {
    if (!rootRef.current || !ready) {
      return;
    }

    const tickerElement = rootRef.current;

    const calculateDuplicates = () => {
      const size = horizontal ? contentWidth : contentHeight;
      const dimension = horizontal ? 'clientWidth' : 'clientHeight';

      return Math.max(Math.ceil((2 * tickerElement[dimension]) / size), 1);
    };

    setDuplicates(calculateDuplicates());
  }, [contentHeight, ready, variant, contentWidth, direction, horizontal]);

  useEffect(() => {
    if (ready) {
      const getX = () => {
        if (vertical) {
          return [0, 0];
        }

        let x = [0, -contentWidth];
        x = direction === TickerDirection.normal ? x : x.reverse();

        return x;
      };

      const getY = () => {
        if (horizontal) {
          return [0, 0];
        }

        let y = [0, -contentHeight];
        y = direction === TickerDirection.normal ? y : y.reverse();

        return y;
      };

      const controls = animate(
        animationScope.current,
        {
          x: getX(),
          y: getY(),
        },
        { ease: 'linear', duration, repeat: Infinity },
      );

      controls.play();
      setAnimationControls(controls);
    }
  }, [
    ready,
    direction,
    contentWidth,
    animate,
    duration,
    animationScope,
    horizontal,
    vertical,
    contentHeight,
  ]);

  const setAnimationSpeed = (speed: number) => {
    if (animationControls) {
      animationControls.speed = speed;
    }
  };

  return (
    <div
      className={cx(styles.root, styles[variant], ready && styles.ready, className)}
      ref={rootRef}
      onMouseEnter={() => setAnimationSpeed(0.01)}
      onMouseLeave={() => setAnimationSpeed(1)}
    >
      <div ref={animationScope} className={cx(styles.wrapper, wrapperClassName)}>
        {/* eslint-disable react/no-array-index-key */}
        {children.map((item, index) => (
          <div key={index} id={`${id}-${index}`}>
            {item}
          </div>
        ))}

        {[...Array(duplicates)].map(() =>
          children.map((item, index) => (
            <div key={index} aria-hidden="true">
              {item}
            </div>
          )),
        )}
        {/* eslint-enable react/no-array-index-key */}
      </div>
    </div>
  );
};

export { Ticker };
export type { TickerProps };
