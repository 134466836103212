import PropTypes from 'prop-types';
import withRouter from '../../hoc/withRouter';
import { Link } from './Link';

const Anchor = ({
  className,
  id,
  router: {
    location: { pathname, search },
  },
  children,
  onClick,
  scroll,
  shallow,
}) => (
  <Link
    className={className}
    to={`${pathname}${search}#${id}`}
    onClick={onClick}
    scroll={scroll}
    shallow={shallow}
  >
    {children}
  </Link>
);

Anchor.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  router: PropTypes.shape().isRequired,
  scroll: PropTypes.bool,
  shallow: PropTypes.bool,
};

Anchor.defaultProps = {
  className: undefined,
  onClick: undefined,
  scroll: true,
  shallow: true,
};

export default withRouter(Anchor);
