import { Text } from '@carvertical/ui';
import { Trans, useTranslation } from 'next-i18next';
import cx from 'classnames';
import YoutubeIcon from 'assets/icons/social/youtube-play.svg';
import InstagramIcon from 'assets/icons/instagram.svg';
import type { CampaignBadgeData, CampaignPartnerBadge } from '../../types';

type CampainBadgeProps = CampaignBadgeData;

const CampaignBadge = (props: CampainBadgeProps) => {
  const { t } = useTranslation(['business', 'common']);
  const isPartnerBadge = getIsPartnerBadge(props);
  const { className, partner } = props;

  const renderDetails = () => {
    if (isPartnerBadge) {
      const { foundedIn, vehiclesSold, luxury } = props;

      return (
        <>
          <Text as="span" variant="s" inline>
            <Trans
              i18nKey="partner.foundedInLabel"
              t={t}
              as="span"
              values={{ year: foundedIn }}
              components={{
                1: <Text as="span" variant="s+" inline />,
              }}
            />
          </Text>

          <Text as="span" variant="s" inline>
            <Trans
              i18nKey={luxury ? 'partner.luxuryVehiclesSoldlabel' : 'partner.vehiclesSoldLabel'}
              t={t}
              as="span"
              values={{ count: vehiclesSold }}
              components={{
                1: <Text as="span" variant="s+" inline />,
              }}
            />
          </Text>
        </>
      );
    }

    const { followers, platform } = props;
    return (
      <div className="flex items-center gap-1">
        {platform === 'instagram' && <InstagramIcon className="h-3 w-3" />}

        {platform === 'youtube' && <YoutubeIcon className="h-3 w-3" />}

        <Text as="span" variant="s" inline>
          <Text as="span" variant="s+" inline>
            {followers}{' '}
          </Text>

          {t('common:social.followersLabel')}
        </Text>
      </div>
    );
  };

  return (
    <div
      className={cx(
        'absolute flex flex-col gap-1 rounded-s bg-white bg-opacity-75 px-3 py-1.5',
        className,
      )}
    >
      <Text as="span" variant="m+">
        {partner}
      </Text>

      {renderDetails()}
    </div>
  );
};

function getIsPartnerBadge(data: CampaignBadgeData): data is CampaignPartnerBadge {
  return 'vehiclesSold' in data && 'foundedIn' in data;
}

export { CampaignBadge };
