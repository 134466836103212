import { Container } from '@carvertical/ui';
import { WebinarForm } from './WebinarForm';
import { WebinarCarousel } from './WebinarCarousel';

const WebinarHero = () => {
  return (
    <div className="relative flex items-center bg-white md:bg-gradient-to-r md:from-white md:from-50% md:to-blue-900 md:to-50% md:pb-6 md:pt-15">
      <Container>
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-17">
          <div className="max-w-72 max-md:mx-auto max-md:py-6 md:max-w-64">
            <WebinarForm />
          </div>

          <div className="max-md:-ml-2 max-md:w-[calc(100%+32px)] max-md:bg-blue-900 max-md:px-2 max-md:py-8">
            <WebinarCarousel />
          </div>
        </div>
      </Container>
    </div>
  );
};

export { WebinarHero };
