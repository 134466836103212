import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { Container, Heading, Text } from '@carvertical/ui';
import type { ParseKeys } from 'i18next';
import { Section } from 'components/landings/common/Section';
import { SocialLink } from 'modules/shared';
import vytautasUrl from '../assets/images/team/vytautas.jpg';
import ievaMejeryteUrl from '../assets/images/team/ieva.jpg';
import kotrynaUrl from '../assets/images/team/kotryna.jpg';
import robertasUrl from '../assets/images/team/robertas.jpg';
import karolinaUrl from '../assets/images/team/karolina.jpg';
import lauraUrl from '../assets/images/team/laura.jpg';
import danielUrl from '../assets/images/team/daniel.jpg';
import jauniusUrl from '../assets/images/team/jaunius.jpg';
import ievaPauliukonieneUrl from '../assets/images/team/ieva-pauliukoniene.jpg';

type Person = {
  imageUrl: string;
  name: string;
  roleKey: ParseKeys<'business'>;
  context?: 'male' | 'female';
  linkedinHandle: string;
};

const PEOPLE: Person[] = [
  {
    imageUrl: vytautasUrl,
    name: 'Vytautas Nemura',
    roleKey: 'teamSection.b2bHeadTitle',
    context: 'male',
    linkedinHandle: 'vytautasnemura',
  },
  {
    imageUrl: karolinaUrl,
    name: 'Karolina Žilinskė',
    roleKey: 'teamSection.salesRepresentativeFranceTitle',
    context: 'female',
    linkedinHandle: 'karolina-žilinskė-3808a445',
  },
  {
    imageUrl: robertasUrl,
    name: 'Robertas Boravskis',
    roleKey: 'teamSection.marketingLeadTitle',
    linkedinHandle: 'robertas-boravskis',
  },
  {
    imageUrl: lauraUrl,
    name: 'Laura Kazakaitė',
    roleKey: 'teamSection.salesRepresentativeItalyTitle',
    context: 'female',
    linkedinHandle: 'laurakazakaite',
  },
  {
    imageUrl: danielUrl,
    name: 'Daniel Artisiuk',
    roleKey: 'teamSection.salesRepresentativePolandTitle',
    linkedinHandle: 'danielartisiuk',
  },
  {
    imageUrl: jauniusUrl,
    name: 'Jaunius Kadunas',
    roleKey: 'teamSection.salesRepresentativeTitle',
    linkedinHandle: 'kadunas',
  },
  {
    imageUrl: ievaMejeryteUrl,
    name: 'Ieva Mejerytė',
    roleKey: 'teamSection.b2bCommunicationsTitle',
    context: 'female',
    linkedinHandle: 'mejeryte',
  },
  {
    imageUrl: kotrynaUrl,
    name: 'Kotryna Mažeikytė',
    roleKey: 'teamSection.salesRepresentativeFranceTitle',
    context: 'female',
    linkedinHandle: 'kotrynamazeikyte',
  },
  {
    imageUrl: ievaPauliukonieneUrl,
    name: 'Ieva Pauliukonienė',
    roleKey: 'teamSection.strategicPartnershipManagerTitle',
    context: 'female',
    linkedinHandle: 'ievapaul',
  },
];

const TeamSection = () => {
  const { t } = useTranslation('business');

  return (
    <Section>
      <Container>
        <div className="flex flex-col items-stretch gap-5 lg:gap-8">
          <div className="flex flex-col items-center gap-2">
            <Heading as="h2" variant="l" align="center">
              {t('teamSection.title')}
            </Heading>

            <Text variant="l" align="center">
              {t('teamSection.subtitle')}
            </Text>
          </div>

          <ul className="my-0 flex list-none flex-wrap justify-center gap-x-2 gap-y-5 p-0 sm:max-lg:grid sm:max-lg:grid-cols-[repeat(auto-fill,minmax(200px,_1fr))] md:gap-x-4 md:gap-y-8">
            {PEOPLE.map(({ context, imageUrl, name, roleKey, linkedinHandle }) => (
              <li
                key={name}
                className="w-full max-w-28 sm:max-lg:max-w-none lg:w-[15%] lg:max-w-32"
              >
                <figure className="m-0 h-full w-full">
                  <div className="flex h-full flex-col gap-2">
                    <div className="relative aspect-square w-full">
                      <Image
                        src={imageUrl}
                        alt={name}
                        quality={100}
                        fill
                        className="rounded-s object-cover"
                      />
                    </div>

                    <figcaption className="flex h-full">
                      <div className="flex flex-col items-start gap-1.5">
                        <div className="flex flex-col">
                          <Text variant="m+">{name}</Text>

                          {roleKey && (
                            <Text variant="s" textColor="darkSecondary">
                              {t(roleKey, { context })}
                            </Text>
                          )}
                        </div>

                        <SocialLink
                          className="mt-auto"
                          to={`https://www.linkedin.com/in/${linkedinHandle}/`}
                          platform="linkedin"
                          size="s"
                          variant="light"
                        />
                      </div>
                    </figcaption>
                  </div>
                </figure>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </Section>
  );
};

export { TeamSection };
