import { Text } from '@carvertical/ui';
import { isExistingMarket } from 'utils/market';
import { useRouteData } from 'context/RouteDataProvider';
import IsoCertifiedImage from '../assets/images/iso-certified.svg';

const IsoCertifiedIllustration = () => {
  const { market } = useRouteData();
  const germanMarket = isExistingMarket({
    markets: ['germany'],
    market,
  });

  return (
    <div className="flex flex-col items-center gap-2">
      {germanMarket && (
        <Text className="text-inherit" variant="xs" as="span" align="center">
          Unser Informationssicherheits-Managementsystem ist zertifiziert
        </Text>
      )}

      <IsoCertifiedImage className="h-10 w-10" />
    </div>
  );
};

export { IsoCertifiedIllustration };
