import { Stack, Button, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { CheckIconM } from '@carvertical/icons/react';
import Anchor from 'components/common/Anchor';
import type { Config } from '../types';

type SolutionsBlockProps = Pick<Config, 'solutions' | 'actions'>;

const SolutionsBlock = ({ solutions, actions }: SolutionsBlockProps) => {
  const { t } = useTranslation(['business', 'common']);

  return (
    <Stack
      gap={{ mobileUp: 3, tabletLandscapeUp: 4 }}
      className="w-full lg:items-start lg:justify-start"
      crossAxisAlign="center"
    >
      <Stack
        gap={{ mobileUp: 1, tabletLandscapeUp: 2 }}
        className="m-0 list-none p-0 lg:items-start lg:justify-start"
        as="ul"
      >
        {solutions.map((key) => (
          <Stack as="li" key={key} type="horizontal" gap={1}>
            <CheckIconM className="text-black" />

            <Text key={key} variant="m+">
              {t(key)}
            </Text>
          </Stack>
        ))}
      </Stack>

      <div className="flex w-full flex-wrap justify-center gap-3 lg:items-start lg:justify-start">
        {actions.map(({ className, id, labelKey, variant }) => (
          <Button
            href={id}
            as={Anchor}
            key={id}
            // @ts-expect-error - TS(2322)
            scroll={false}
            id={id}
            variant={variant}
            className={cx('w-full sm:w-[min(100%,_224px)]', className)}
            size="l"
          >
            {t(labelKey)}
          </Button>
        ))}
      </div>
    </Stack>
  );
};

export { SolutionsBlock };
