import { z } from 'zod';
import { ERROR_TRANSLATION_KEYS } from 'constants/i18n';
import { emailSchema } from 'modules/shared/schemas';

const basicFormSchema = z.object({
  email: emailSchema,
  name: z.string().trim().min(1, { message: ERROR_TRANSLATION_KEYS.required }),
  companyName: z.string().trim().min(1, { message: ERROR_TRANSLATION_KEYS.required }),
  termsAccepted: z.literal(true).optional(),
});

type BasicFormFields = z.infer<typeof basicFormSchema>;

export { basicFormSchema };
export type { BasicFormFields };
