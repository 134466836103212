import { useMutation } from '@tanstack/react-query';
import { postB2bContact } from '../api';
import type { B2bContactResponse, B2bContactParams } from '../types';

const useB2bContactMutation = () => {
  const { isError, isSuccess, isPending, mutate, reset } = useMutation<
    B2bContactResponse,
    unknown,
    B2bContactParams
  >({ mutationFn: postB2bContact });

  return {
    contactB2b: mutate,
    contactingB2b: isPending,
    b2bContacted: isSuccess,
    b2bContactError: isError,
    reset,
  };
};

export { useB2bContactMutation };
