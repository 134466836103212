import { reduce } from 'lodash';
import type { Variants } from 'framer-motion';
import { useRouteData } from 'context/RouteDataProvider';
import { SwipeableSections, type Step } from 'components/landings/common/SwipeableSections';
import type { WebinarPresentation } from '../../types';
import { WEBINARS } from '../../constants';
import { WebinarCarouselItem } from './WebinarCarouselItem';

const SWIPE_INTERVAL = 3000;
const ANIMATION_VARIANTS: Variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      ease: 'easeOut',
    },
  },
};

const getSteps = (presentations?: WebinarPresentation[]) =>
  reduce(
    presentations,
    (result: Step[], webinarPresentation) => {
      if (webinarPresentation) {
        result.push({
          Component: <WebinarCarouselItem {...webinarPresentation} />,
        });
      }

      return result;
    },
    [],
  );

const WebinarCarousel = () => {
  const { market } = useRouteData();
  const { presentations } = WEBINARS[market.id] || {};
  const steps = getSteps(presentations);

  return (
    <SwipeableSections
      fullWidth
      steps={steps}
      variant="light"
      swipeInterval={SWIPE_INTERVAL}
      animationVariants={ANIMATION_VARIANTS}
    />
  );
};

export { WebinarCarousel };
