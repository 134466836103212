import type { MarketId } from 'types/market';
import { type ExistingMarkets, getAvailableMarkets } from 'utils/market';

const MILES_MARKETS: ExistingMarkets = ['britain', 'usa'];

const EXCLUDED_MARKETS: MarketId[] = ['europe'];

const TOTAL_MARKETS = Object.keys(getAvailableMarkets(EXCLUDED_MARKETS)).length;

export { MILES_MARKETS, TOTAL_MARKETS, EXCLUDED_MARKETS };
