import cx from 'classnames';
import ArtDirectedImage from 'components/common/ArtDirectedImage';
import { useCampaign } from '../../hooks';
import { CampaignBadge } from './CampaignBadge';

const CampaignHeroIllustration = () => {
  const {
    campaignContent: { illustration },
    campaignSuccessful,
  } = useCampaign();

  if (!illustration) {
    return null;
  }

  const visibleIllustration = campaignSuccessful ? illustration.success : illustration.default;
  const { className, successClassName } = illustration;

  return (
    <div className="relative -ml-2 flex h-64 w-[calc(100%+32px)] justify-center overflow-hidden sm:h-72 md:absolute md:left-[52%] md:top-0 md:ml-0 md:h-full md:w-[48%]">
      <ArtDirectedImage
        className={cx('w-[100%] object-cover', className, campaignSuccessful && successClassName)}
        src={visibleIllustration}
        quality={100}
        // @ts-expect-error TS(2739)
        srcSet={{
          forTabletLandscapeUp: {
            src: visibleIllustration,
            height: 1100,
            width: 1000,
          },
          forTabletPortraitUp: {
            src: visibleIllustration,
            height: 576,
            width: 480,
          },
        }}
        height={531}
        width={442}
      />

      {!campaignSuccessful &&
        illustration.badges?.map(({ partner, ...rest }) => (
          <CampaignBadge key={partner} partner={partner} {...rest} />
        ))}
    </div>
  );
};

export { CampaignHeroIllustration };
