import { useRouter } from 'next/router';
import qs from 'querystringify';
import { Button, type ButtonProps } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import type { Package } from 'types/pricing';
import { useSuggestCorrectMarket } from 'modules/shared';
import { Link } from './Link';

type BuyReportButtonProps = React.PropsWithChildren<
  ButtonProps & {
    preselectedPackageId?: Package['id'];
    forced?: boolean;
    subscription?: boolean;
    preventMarketSuggestion?: boolean;
  }
>;

type Params = {
  package?: Package['id'];
  campaign?: string | string[] | undefined;
  segment?: string | string[] | undefined;
  subscription?: string;
};

const BuyReportButton = ({
  as: RenderAs = Button,
  children,
  forced = true,
  preselectedPackageId,
  subscription = false,
  preventMarketSuggestion = false,
  ...props
}: BuyReportButtonProps) => {
  const { t } = useTranslation();
  const { suggestOnCheckout, suggestedMarket } = useSuggestCorrectMarket();
  const {
    query: { campaign, segment },
  } = useRouter();

  const params: Params = {};
  if (preselectedPackageId) {
    params.package = preselectedPackageId;
  }
  if (campaign) {
    params.campaign = campaign;
  }
  if (segment) {
    params.segment = segment;
  }
  if (subscription) {
    params.subscription = '';
  }

  const checkoutUrl = `/checkout${qs.stringify(params, true)}`;

  const startCheckout = (event: MouseEvent) => {
    if (!suggestedMarket || preventMarketSuggestion) {
      return;
    }

    event.preventDefault();
    suggestOnCheckout(checkoutUrl, forced);
  };

  return (
    <RenderAs as={Link} to={checkoutUrl} onClick={startCheckout} {...props}>
      {children || t('pricingSection.buyReportAction')}
    </RenderAs>
  );
};

export { BuyReportButton };
