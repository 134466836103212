import { useCallback } from 'react';
import { useRouteData } from 'context/RouteDataProvider';
import { isExistingMarket } from 'utils/market';
import type { BusinessPagePartialRouteData, CampaignPartnerId } from '../types';
import { CAMPAIGN_CONTENT } from '../constants';
import { useCampaignActions, useCampaignSuccessful } from './useCampaignStore';

const useCampaign = () => {
  const { campaign, market, partnerId } = useRouteData<BusinessPagePartialRouteData>();
  const campaignSuccessful = useCampaignSuccessful();
  const { setCampaignSuccessful } = useCampaignActions();

  const campaignMarket = isExistingMarket({
    markets: ['poland', 'britain', 'france', 'italy'],
    market,
  });

  const campaignContent = CAMPAIGN_CONTENT[(partnerId as CampaignPartnerId) || market.id];

  const markCampaignAsSuccessful = useCallback(() => {
    setCampaignSuccessful(true);
  }, [setCampaignSuccessful]);

  return {
    campaignShown: campaign && campaignMarket,
    campaignContent,
    campaignSuccessful,
    markCampaignAsSuccessful,
  };
};

export { useCampaign };
