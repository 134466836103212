import { motion, type AnimationProps } from 'framer-motion';
import CircleCheckIcon from 'assets/illustrations/circle-check.svg';
import { useRouteData } from 'context/RouteDataProvider';
import { WEBINARS } from '../../constants';

const ANIMATION_PROPS: AnimationProps = {
  variants: {
    hidden: { opacity: 0, height: 0 },
    visible: { opacity: 1, height: 'auto' },
  },
  transition: {
    duration: 0.5,
    ease: 'easeInOut',
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
};

const WebinarSubmitConfirmation = () => {
  const { market } = useRouteData();
  const { successTitle, successDescription } = WEBINARS[market.id] || {};

  return (
    <motion.div {...ANIMATION_PROPS} className="flex flex-col gap-2 py-13">
      <div className="flex flex-col items-center">
        <CircleCheckIcon original className="h-15 w-15" />

        <h1 className="text-2xl text-center">{successTitle}</h1>

        <p className="max-w-60 text-center text-l">{successDescription}</p>
      </div>
    </motion.div>
  );
};

export { WebinarSubmitConfirmation };
