import { Container, Heading, Stack, Text, Button, Grid, GridItem } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { isEmpty } from 'lodash';
import { useRouteData } from 'context/RouteDataProvider';
import { Link } from 'components/common/Link';
import { BLOG_ROOT_PATH } from 'modules/content/constants';
import { ArticleCard } from 'modules/content/components/blog/ArticleCard';
import { Section, type SectionProps } from './Section';
import styles from './BlogSection.module.scss';

type BlogSectionProps = {
  title?: string;
  subtitleShown?: boolean;
  subtitle?: string;
  sectionProps?: SectionProps;
};

const COLUMN_SPAN = 4;

const BlogSection = ({ sectionProps, subtitleShown = true, subtitle, title }: BlogSectionProps) => {
  const { content } = useRouteData();
  const { t } = useTranslation();

  const { articles, category } = content?.blog || {};

  if (isEmpty(articles)) {
    return null;
  }

  const articlesLength = articles?.length || 0;
  const columnStartIndexByLength = {
    1: 5,
    2: 3,
    3: 1,
  };
  const columnStartIndex =
    columnStartIndexByLength[articlesLength as keyof typeof columnStartIndexByLength];

  return (
    <Section {...sectionProps}>
      <Container>
        <Stack gap={{ mobileUp: 5, desktopUp: 8 }} crossAxisAlign="center">
          <Stack gap={2} crossAxisAlign="center">
            <Heading as="h2" variant="l">
              {title || t('blogSection.title')}
            </Heading>

            {subtitleShown && <Text variant="l">{subtitle || t('blogSection.subtitle')}</Text>}
          </Stack>

          <Stack gap={5} crossAxisAlign="center">
            <Grid
              columnCount={{
                mobileUp: 1,
                tabletPortraitUp: 8,
                tabletLandscapeUp: 12,
              }}
              columnGap={4}
              rowGap={4}
            >
              {articles?.map((article, index) => (
                <GridItem
                  key={article.id}
                  span={{ mobileUp: 1, tabletLandscapeUp: COLUMN_SPAN }}
                  className={styles.articleCard}
                  style={{
                    '--columnStart': columnStartIndex
                      ? +columnStartIndex + index * COLUMN_SPAN
                      : 'auto',
                  }}
                >
                  <ArticleCard article={article} category={category} />
                </GridItem>
              ))}
            </Grid>

            <Button
              as={Link}
              className={styles.button}
              href={BLOG_ROOT_PATH}
              size="l"
              trailingIcon="arrow-right"
              variant="outlined"
            >
              {t('blogSection.allAction')}
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Section>
  );
};

export { BlogSection };
